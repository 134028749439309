import { useQuery } from "~/features/studio-2/query/state/query";
import { profile } from "@fscrypto/domain";
import { QueryVisTabs } from "../query-vis-tabs";
import { createStore, useStore } from "@fscrypto/state-management";
import MarketingAuthModal from "~/features/app-shell/marketing/marketing-auth-modal";

interface QueryPublicContainerProps {
  queryId: string;
  owner: profile.Profile;
  children: React.ReactNode;
}

const authModalOpenStore = createStore(false);
export const setAuthModalOpen = () => authModalOpenStore.set(true);
export const closeAuthModal = () => authModalOpenStore.set(false);

export const QueryPublicContainer = ({ queryId, children, owner }: QueryPublicContainerProps) => {
  const query = useQuery(queryId);
  const authModalOpen = useStore(authModalOpenStore);
  if (!query) return null;
  return (
    <div className="flex h-full flex-col">
      <QueryVisTabs queryId={queryId} isPublic={true} owner={owner} />
      {children}
      {authModalOpen && (
        <div className="absolute inset-0 backdrop-blur">
          <MarketingAuthModal
            redirectBackOnClose={false}
            onOpenChange={(open) => authModalOpenStore.set(open)}
            defaultOpen={true}
            persona="analyst"
            open={true}
            origin="query-fork"
          />
        </div>
      )}
    </div>
  );
};
